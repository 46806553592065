/* eslint-disable no-param-reassign */
import { pdfjs, Document, Page } from 'react-pdf';
import { useEffect, useMemo, useState } from 'react';
import AppBox from 'version-two/components/chakraLayoutOverrides/AppBox';
import appColors from 'version-two/theme/appColors';
import { CloseButton, useBreakpointValue } from '@chakra-ui/react';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import useGetFileData from 'version-two/hooks/useGetFileData';
import { useNavigate } from 'react-router-dom';
import getIsWebView from 'version-two/utils/getIsWebView';
import { PdfWrapperBlock } from 'version-two/pages/common/AppPdfViewer/AppPdfViewer.styles';
import getIsIOSDevice from 'version-two/utils/getIsIOSDevice';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import { BsZoomIn, BsZoomOut } from 'react-icons/bs';
import { Icon } from '@chakra-ui/icons';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

function AppPdfViewer() {
  const [pdfBlob, setPdfBlob] = useState(null);
  const [pdfBlobLoading, setPdfBlobLoading] = useState(true);
  const [pdfRenderLoading, setPdfRenderLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(null);
  const [pageScale, setPageScale] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);

  // Sometimes promise is undefined. To prevent this we pass the promise from react
  if (typeof Promise.withResolvers === 'undefined') {
    if (window)
      // This does not exist outside of polyfill which this is doing
      window.Promise.withResolvers = () => {
        let resolve;
        let reject;
        const promise = new Promise((res, rej) => {
          resolve = res;
          reject = rej;
        });
        return { promise, resolve, reject };
      };
  } else {
    global.Promise.withResolvers = () => {
      let resolve;
      let reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
  }

  // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  //   'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  //   import.meta.url,
  // ).toString();

  // eslint-disable-next-line max-len
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

  const isMobile = useBreakpointValue({ base: true, sm: false });

  const navigate = useNavigate();
  const isWebView = useMemo(() => getIsWebView(), []);

  const { data, loading } = useGetFileData();

  function onDocumentLoadSuccess({ numPages }) {
    setTotalPages(numPages);
  }

  // const canGoToNext = () => currentPage < totalPages;
  //
  // const canGoToPrev = () => currentPage > 1;

  // const handlePrevPage = () => {
  //   if (canGoToPrev()) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };
  // const handleNextPage = () => {
  //   if (canGoToNext()) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // useEffect(() => {
  //   setPdfRenderLoading(true);
  // }, [currentPage]);

  useEffect(() => {
    if (!loading && data) {
      fetch(data)
        .then((res) => res.blob())
        .then((file) => {
          setPdfBlob(file);
          setPdfBlobLoading(false);
        });
    }
  }, [data, loading]);

  const pdfLoading = loading || pdfBlobLoading;

  const renderType = getIsIOSDevice() ? 'svg' : 'canvas';

  const handlePageZoomIn = () => {
    setPageScale((prevState) => prevState + 0.1);
  };

  const handlePageZoomOut = () => {
    setPageScale((prevState) => prevState - 0.1);
  };

  useEffect(() => {
    const ensureLinksOpenInNewTab = () => {
      const links = document.querySelectorAll('a');
      links.forEach((link) => {
        if (!link.target || link.target !== '_blank') {
          link.target = '_blank';
          link.rel = 'noopener noreferrer';
        }
      });
    };

    ensureLinksOpenInNewTab();

    const observer = new MutationObserver(() => ensureLinksOpenInNewTab());
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  return (
    <AppLoader isLoading={pdfLoading}>
      {!pdfLoading && (
        <AppBox
          customStyles={{
            height: '100vh',
            width: '100%',
            overflow: 'scroll',
            backgroundColor: appColors.brandGrey['500'],
          }}
        >
          {isWebView && (
            <CloseButton
              onClick={() => navigate(-1)}
              size="md"
              style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
              }}
              zIndex="100"
            />
          )}
          <AppFlex
            customStyles={{
              justifyContent: 'center',
              position: 'fixed',
              right: 0,
              left: 0,

              paddingY: '10px',

              background: 'rgba(0,0,0, 0.3)',
              zIndex: '99',
            }}
          >
            <Icon
              as={BsZoomIn}
              mx={3}
              boxSize={6}
              cursor="pointer"
              onClick={handlePageZoomIn}
            />
            <Icon
              as={BsZoomOut}
              mx={3}
              boxSize={6}
              cursor="pointer"
              onClick={handlePageZoomOut}
            />
          </AppFlex>
          <PdfWrapperBlock>
            <AppLoader
              height="100px"
              isLoading={pdfRenderLoading}
              customStyles={{
                position: 'absolute',
                zIndex: 100,
                right: '47%',
                top: '44%',
              }}
            />

            {pdfBlob && (
              <Document
                file={data}
                /* eslint-disable-next-line react/jsx-no-bind */
                onLoadSuccess={onDocumentLoadSuccess}
                renderMode={renderType}
                loading={<AppLoader />}
              >
                {Array.from(new Array(totalPages), (el, index) => (
                  <AppBox customStyles={{ my: 5 }}>
                    <Page
                      // object-fit="fill"
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={isMobile && 350}
                      loading={<AppLoader />}
                      onRenderSuccess={() => {
                        setPdfRenderLoading(false);
                      }}
                      style={{
                        marginTop: '200px',
                      }}
                      scale={pageScale}
                      renderTextLayer={false}
                    />
                  </AppBox>
                ))}

                {/* <IconWrapper isMobile={isMobile}> */}
                {/*  <IconButton */}
                {/*    disabled={!canGoToPrev()} */}
                {/*    colorScheme="blue" */}
                {/*    aria-label="go-previous" */}
                {/*    icon={<ChevronLeftIcon fontSize={fontSizes.md} />} */}
                {/*    backgroundColor={appColors.brandGrey['50']} */}
                {/*    color={appColors.brandGrey['900']} */}
                {/*    mr={2} */}
                {/*    onClick={handlePrevPage} */}
                {/*  /> */}
                {/*  <AppText title={`${currentPage} of ${totalPages}`} /> */}
                {/*  <IconButton */}
                {/*    disabled={!canGoToNext()} */}
                {/*    colorScheme="blue" */}
                {/*    aria-label="go-next" */}
                {/*    icon={<ChevronRightIcon fontSize={fontSizes.md} />} */}
                {/*    backgroundColor={appColors.brandGrey['50']} */}
                {/*    color={appColors.brandGrey['900']} */}
                {/*    ml={2} */}
                {/*    onClick={handleNextPage} */}
                {/*  /> */}
                {/* </IconWrapper> */}
              </Document>
            )}
          </PdfWrapperBlock>
        </AppBox>
      )}
    </AppLoader>
  );
}

export default AppPdfViewer;
