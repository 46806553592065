import ProgramDrawableHeader from 'version-two/components/ProgramDrawableHeader/ProgramDrawableHeader';
import AppBox from 'version-two/components/chakraLayoutOverrides/AppBox';
import AppText from 'version-two/components/Typography/AppText';
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  ListItem,
  UnorderedList,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react';
import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
import AppButton from 'version-two/components/Typography/AppButton';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import appColors from 'version-two/theme/appColors';
import { Mp3Icon } from 'version-two/assets';
import FileUploadDialog from 'version-two/components/FileUploadDialog/FileUploadDialog';
import { useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  GET_TEACHER_LIVE_CLASS_MODULE_IN_DETAIL,
  LIVE_CLASS_TEACHER_FILE_UPLOAD,
} from 'version-two/api/urls/teacher';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import makePostRequest from 'version-two/api/utils/makePostRequest';
import ContentTitleCard from 'version-two/components/ContentTitleCard/ContentTitleCard';
import { useParams } from 'react-router-dom';

function TeacherProgramDetailDrawable({ isOpen, onClose, activeModule }) {
  const {
    isOpen: fileDialogIsOpen,
    onOpen: fileDialogOnOpen,
    onClose: fileDialogOnClose,
  } = useDisclosure();

  const fileDialogCancelRef = useRef();
  const { moduleSlug } = useParams();
  // console.log('voxguru-v1/api check', useParams());

  const { data: liveClassModuleData, isLoading: liveClassModuleDataIsLoading } =
    useQuery(GET_TEACHER_LIVE_CLASS_MODULE_IN_DETAIL(moduleSlug), () =>
      makeGetRequest(GET_TEACHER_LIVE_CLASS_MODULE_IN_DETAIL(moduleSlug)),
    );

  const queryClient = useQueryClient();
  const { mutate: teacherFileUploadMutate } = useMutation(
    (body) =>
      makePostRequest(LIVE_CLASS_TEACHER_FILE_UPLOAD(activeModule), body),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(
          GET_TEACHER_LIVE_CLASS_MODULE_IN_DETAIL(activeModule),
        ),
    },
  );

  const getFile = (val) => {
    if (val?.file_type === 'pitch_audio') return val?.pitch_file?.url;
    return val?.file;
  };

  const handleTeacherNoteUpload = ({ fileData, name }) => {
    const reqBody = {
      identity: name,
      file: fileData.id,
    };

    teacherFileUploadMutate(reqBody);
    fileDialogOnClose();
  };
  const drawerSize = useBreakpointValue({ base: 'xs', sm: 'sm', md: 'md' });
  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
        size={drawerSize}
      >
        <DrawerOverlay>
          <DrawerContent overflow="scroll">
            <AppLoader isLoading={liveClassModuleDataIsLoading}>
              {!liveClassModuleDataIsLoading && (
                <>
                  <ProgramDrawableHeader
                    programModule={`MODULE ${liveClassModuleData?.data?.position}`}
                    programTitle={liveClassModuleData?.data.identity}
                  />

                  {liveClassModuleData?.data.objectives && (
                    <AppBox variant="programDrawableBlock">
                      <AppText
                        title="Objectives"
                        variant="programDrawableSubTitle"
                      />
                      <UnorderedList>
                        {Array.isArray(liveClassModuleData?.data.objectives) ? (
                          liveClassModuleData?.data.objectives.map((data) => (
                            <ListItem>
                              <AppText title={data} size="xs" />
                            </ListItem>
                          ))
                        ) : (
                          <ListItem>
                            <AppText
                              title={liveClassModuleData?.data.objectives}
                              size="xs"
                            />
                          </ListItem>
                        )}
                      </UnorderedList>
                    </AppBox>
                  )}

                  {/* Active Students */}
                  {/* <AppFlex variant="programDrawableBlock">
                    <AppText
                      title={`Active Students 
                      (${
                        liveClassModuleData.data.accessible_students_count || 0
                      })`}
                      variant="programDrawableSubTitle"
                    />
                    {canManageAccess && (
                      <AppButton
                        title="Manage"
                        variant="programDrawableButton"
                        onClick={() =>
                          activeStudentManage({
                            id: activeModule,
                            identity: activeModuleName,
                          })
                        }
                      />
                    )}
                  </AppFlex> */}

                  {/* Core Content */}
                  <AppBox variant="programDrawableBlock">
                    <AppFlex
                      customStyles={{
                        alignItems: 'center',
                      }}
                    >
                      <AppText
                        title="Core Content"
                        variant="programDrawableSubTitle"
                      />
                      <InfoOutlineIcon
                        ml={2}
                        color={appColors.brandGrey['200']}
                      />
                    </AppFlex>

                    {Array.isArray(liveClassModuleData?.data.core_contents) &&
                      liveClassModuleData?.data.core_contents.map((data) => (
                        <ContentTitleCard
                          file={getFile(data)}
                          icon={Mp3Icon}
                          fileId={data.id}
                          title={data.identity}
                          thedata={data}
                          corecontent
                          pitch={data?.pitch_file?.pitch}
                        />
                      ))}
                  </AppBox>

                  {/* Teachers Note */}
                  <AppFlex variant="programDrawableBlock">
                    <AppFlex
                      customStyles={{
                        alignItems: 'center',
                      }}
                    >
                      <AppText
                        title="Teachers Note"
                        variant="programDrawableSubTitle"
                      />
                      <InfoOutlineIcon
                        ml={2}
                        color={appColors.brandGrey['200']}
                      />
                    </AppFlex>

                    <AppButton
                      title="Upload"
                      variant="programDrawableButton"
                      onClick={fileDialogOnOpen}
                    />
                  </AppFlex>
                  {liveClassModuleData?.data?.teacher_contents && (
                    <AppBox variant="programDrawableBlock">
                      {liveClassModuleData?.data?.teacher_contents.map(
                        (teacherNoteData) => (
                          <ContentTitleCard
                            file={teacherNoteData.file}
                            fileId={teacherNoteData.id}
                            title={teacherNoteData.identity}
                            shareCount={
                              teacherNoteData.accessible_students_count
                            }
                            activeModule={activeModule}
                            existingStudents={teacherNoteData?.students}
                            studentsWithAccess={
                              teacherNoteData?.accessible_students
                            }
                            hasShare
                          />
                        ),
                      )}
                    </AppBox>
                  )}
                </>
              )}
            </AppLoader>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      <FileUploadDialog
        isOpen={fileDialogIsOpen}
        onClose={fileDialogOnClose}
        leastDestructiveRef={fileDialogCancelRef}
        fileUploadCallback={handleTeacherNoteUpload}
      />
    </>
  );
}
export default TeacherProgramDetailDrawable;
